<template>
  <div
    class="min-w-[300px] z-20 bg-white shadow-lg rounded-md text-sm text-gray-500 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dropdown-wrapper"
  >
    <div class="m-4 space-y-4">
      <h2 class="font-bold">
        {{ `${$t("COMMON.IMPERSONATE")} ${$t("COMMON.USER")}` }}
      </h2>
      <lf-dropdown-with-api-search
        :value="activeClient"
        :placeholder="$t('DEALS.ORGANIZATION')"
        :search-term="$t('DEALS.ORGANIZATION')"
        :get-paginated-options="getApprovedClients"
        name="client_id"
        data-cy="organization-dropdown"
        accessor="name"
        full-width
        drop-up
        @change="changeClient"
      />
      <lf-dropdown-with-api-search
        ref="userDropdown"
        :value="activeUser?.id"
        :placeholder="$t('COMMON.USER')"
        :search-term="$t('COMMON.USER')"
        :get-paginated-options="getUsers"
        :disabled="isEmpty(activeClient)"
        name="user_id"
        data-cy="user-dropdown"
        accessor="name"
        full-width
        drop-up
        @change="changeUser"
      >
        <template #option="{ options, optionId }">
          <span>
            <icon-base
              height="40"
              width="40"
              view-box="0 0 50 50"
              icon="profile"
              class="text-grey-icon ml-3"
            />
          </span>
          <span
            class="overflow-hidden ml-3 font-normal capitalize cursor-pointer flex justify-between flex-col"
          >
            <span class="truncate font-bold">
              {{ getUserName(options, optionId) }}
            </span>
            <span class="truncate">
              {{ getUserRole(options, optionId)?.join(", ") }}
            </span>
          </span>
        </template>
      </lf-dropdown-with-api-search>
    </div>
    <div class="m-4">
      <primary-button
        :disabled="disabledButton"
        type="button"
        data-cy="impersonate-button"
        class="w-full"
        @click="impersonate"
      >
        {{ $t("COMMON.IMPERSONATE") }}
      </primary-button>
    </div>
  </div>
</template>

<script setup lang="ts" generic="MyUser extends IUser & { name: string }">
import { computed, ref } from "vue";
import { useStore } from "vuex";

import isEmpty from "lodash/isEmpty";
import type { IClient } from "@/models/clients";
import { getApprovedClients } from "@/services/modules/clients";
import usersApiService from "@/services/modules/users";
import type { IUser } from "@/models/users";
import { formatUserFullName, getUserRoles } from "@/helpers/formatting";
import LfDropdownWithApiSearch from "@/components/ui/inputs/LfDropdownWithApiSearch.vue";
import { usePromiseWrapper } from "@/hooks/common";

import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";

const emit = defineEmits<{
  impersonated: [];
}>();

const { getters, dispatch, commit } = useStore();

const activeClient = ref();
const activeUser = ref();
const userDropdown = ref<InstanceType<typeof LfDropdownWithApiSearch> | null>(
  null
);

const disabledButton = computed(
  () => isEmpty(activeClient.value) || isEmpty(activeUser.value)
);

const alreadyImpersonatingSomeone = computed(() => {
  const hasLocalStorageToken = !!localStorage.getItem("impersonatorToken");
  const impersonatingUser: string | null = getters["auth/impersonating"];
  return !!impersonatingUser && hasLocalStorageToken;
});

const changeClient = (client: IClient) => {
  activeClient.value = client.id;
  userDropdown.value?.resetAll();
  activeUser.value = null;
};

const changeUser = (user: IUser) => {
  activeUser.value = user;
};

const getUsers = async (params: Record<string, unknown>) => {
  const users = await usersApiService.getClientUsers(
    activeClient.value,
    params
  );
  users.data = users.data.map((user: IUser) => ({
    ...user,
    name: formatUserFullName(user)
  }));

  return users;
};

const getUserName = (options: unknown[], optionId: IUser["id"]) => {
  return getUser(options as IUser[], optionId)?.name;
};

const getUserRole = (options: unknown[], optionId: IUser["id"]) => {
  const user = getUser(options as IUser[], optionId);
  return getUserRoles(user.roles);
};

const getUser = (options: IUser[], optionId: IUser["id"]) => {
  return options.find(({ id }) => id === Number(optionId)) as MyUser;
};

const { fetchWrapper: impersonate } = usePromiseWrapper(async () => {
  if (alreadyImpersonatingSomeone.value) {
    return;
  }
  commit("setPreventGlobalMessage", true);
  await dispatch("auth/impersonateUser", activeUser.value?.id);
  await dispatch("auth/getAuthDetails");
  commit("resetHistoryStack", null, { root: true });
  commit("clients/unsetActive");

  emit("impersonated");
  activeClient.value = null;
  activeUser.value = null;
});
</script>
